<template>
  <div v-tooltip="tooltip" class="flex flex-wrap items-center gap-2 py-2">
    <div v-for="stip in visibleTags" :key="stip.id">
      <lf-tag>{{ stip.name }}</lf-tag>
    </div>
    <div class="text-xs">{{ numberOfHiddenTags }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import LfTag from "@/components/ui/text/LfTag.vue";
import type { DocumentTemplate } from "@/models/documentTemplates";

const { model } = defineProps<{
  model: DocumentTemplate;
}>();

const visibleTags = computed(() => model.stips?.slice(0, 2));

const numberOfHiddenTags = computed(() => {
  const numberOfStips = model.stips?.length ?? 0;
  const numberOfVisibleTags = visibleTags.value?.length ?? 0;
  const numberOfHiddenTags = numberOfStips - numberOfVisibleTags;
  return numberOfHiddenTags > 0 ? `+${numberOfHiddenTags}` : "";
});

const tooltip = computed(
  () => model.stips?.map((stip) => stip.name).join(", ") ?? ""
);
</script>
